import {
  BootstrapSizes,
  Button,
  ButtonColors,
  Icon,
  Modal,
  TalentechIcons,
  Typography,
} from "@talentech/components";
import { IAttachmentList } from "project-candidate-types";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";

const PDFDocumentWrapper = styled.div`
  height: 100%;
  position: relative;
`;

const ZoomControls = styled.div`
  position: fixed;
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const PageInfoControls = styled.div`
  position: fixed;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
`;

interface IMediaPreviewModalProps {
  showModal: boolean;
  attachmentFile: IAttachmentList;
  handleCloseModal: () => void;
}

const MediaPreviewModal: React.FC<IMediaPreviewModalProps> = ({
  showModal,
  handleCloseModal,
  attachmentFile,
}) => {
  const { t } = useTranslation();
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [scale, setScale] = useState<number>(0.8);

  const onDocumentLoadSuccess = useCallback(
    ({ numPages }: { numPages: number }) => {
      setNumPages(numPages);
      setPageNumber(1);
    },
    []
  );

  const changePage = useCallback((offset: number) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }, []);

  const zoomIn = useCallback(() => {
    setScale((prevScale) => Math.min(prevScale + 0.1, 2.0));
  }, []);

  const zoomOut = useCallback(() => {
    setScale((prevScale) => Math.max(prevScale - 0.1, 0.5));
  }, []);

  const renderNavigationButtons = () => {
    if (!numPages || numPages <= 1) {
      return [
        <Button outline onClick={handleCloseModal} label="Close" key="close" />,
      ];
    }

    return [
      <Button outline onClick={handleCloseModal} label="Close" key="close" />,
      <Button
        outline
        disabled={pageNumber <= 1}
        onClick={() => changePage(-1)}
        label="Previous"
        key="prev"
      />,
      <Button
        outline
        disabled={pageNumber >= numPages}
        onClick={() => changePage(1)}
        label="Next"
        key="next"
      />,
    ];
  };

  return (
    <Modal
      headerProps={{ className: "px-4 py-2" }}
      bodyProps={{ className: "p-2" }}
      modalProps={{ fullscreen: true }}
      title={attachmentFile?.documentName}
      buttons={renderNavigationButtons()}
      showModal={showModal}
    >
      <ZoomControls className="text-center p-1 bg-white">
        <Button
          disabled={scale <= 0.5}
          onClick={zoomOut}
          color={ButtonColors.LIGHT}
          size={BootstrapSizes.SMALL}
          icon={
            <Icon
              icon={TalentechIcons.REGULAR_01_INTERFACE_ESSENTIALS_ALERTS_MINUS}
            />
          }
        />
        <Typography className="fw-semi-bold">
          {Math.round(scale * 100)}%
        </Typography>
        <Button
          disabled={scale >= 2.0}
          onClick={zoomIn}
          color={ButtonColors.LIGHT}
          size={BootstrapSizes.SMALL}
          icon={
            <Icon
              icon={TalentechIcons.REGULAR_01_INTERFACE_ESSENTIALS_ALERTS_PLUS}
            />
          }
        />
      </ZoomControls>

      <PDFDocumentWrapper>
        <Document
          file={attachmentFile?.documentUrl}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page
            key={`page_${pageNumber}`}
            pageNumber={pageNumber}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            scale={scale}
          />
        </Document>
      </PDFDocumentWrapper>

      {numPages > 1 && (
        <PageInfoControls>
          <Typography className="fw-semi-bold">{`${t(
            "mobapp.common.page"
          )} ${pageNumber} ${t("mobapp.common.of")} ${numPages}`}</Typography>
        </PageInfoControls>
      )}
    </Modal>
  );
};

export default MediaPreviewModal;
